<template>
  <div>
    <el-upload
      class="avatar-uploader"
      :action="$uploadURL"
      :show-file-list="false"
      :on-success="handleSuccess"
      :before-upload="beforeAvatarUpload"
      :disabled="disabled"
    >
      <div v-if="list.length > 0" class="flex">
        <div
          style="margin: 5px"
          class="imgBox"
          v-for="(item, index) in list"
          :key="index"
        >
          <img :src="item.explorateImgUrl" class="avatar" alt="" />
          <div class="img-icon">
            <i
              class="el-icon-zoom-in"
              @click.stop="openElImageViewer(item.explorateImgUrl)"
            ></i>
            <i class="el-icon-delete" @click.stop="handelRemove(item)"></i>
          </div>
        </div>
      </div>
      <i v-else class="el-icon-plus avatar-uploader-icon"></i>
    </el-upload>
    <div class="tp5"><span class="titlesss">*</span>{{ text }}</div>
    <ElImageViewer
      v-if="showElImageViewer"
      :zIndex="8000"
      :src="Elimgs"
      :on-close="handleCloseElImageViewer"
      :url-list="[Elimgs]"
    ></ElImageViewer>
  </div>
</template>

<script>
import { add, remove } from "../../common";
import ElImageViewer from "element-ui/packages/image/src/image-viewer";
export default {
  props: {
    list: {
      type: Array,
      default: () => [],
    },
    text: {
      type: String,
      default: "",
    },
    type: {
      type: String,
      default: "",
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    previewImgList: {
      type: Array,
      default: () => [],
    },
  },
  components: { ElImageViewer },
  data() {
    return {
      dialogImageUrl: "",
      dialogVisibleImg: false,
      preIniIndex: 0, // 初始化展示哪张图片
      showViewer: false, // 显示查看器
      // previewImgList: [] // 预览图片列表
      Elimgs: "",
      showElImageViewer: false,
    };
  },
  methods: {

    handleCloseElImageViewer() {
      this.showElImageViewer = false;
    },
    openElImageViewer(item) {
      this.Elimgs = item;
      this.showElImageViewer = true;
    },
    handleSuccess(response) {
      const arr = add(this.type, response.data.url, this.list);
      this.$emit("emitUpload", arr);
    },
    handelRemove(item) {
      remove(item.explorateImgUrl, this.list);
    },
    //图片校验
    beforeAvatarUpload(file) {
      const ImageType =
        file.type === "image/jpeg" ||
        file.type === "image/png" ||
        file.type === "image/jpg";
      if (!ImageType) {
        this.$message.error("上传图片只能是 JPG 、PNG 、JPEG 格式!");
      }
      return ImageType;
    },
  },
};
</script>

<style lang="scss" scoped>
::v-deep .el-dialog__body {
  padding-top: 0 !important;
}
.imgss {
  margin: 5px;
}
.bigImg {
  width: 500px;
}

.fixed {
  position: fixed;
  left: 0;
  bottom: 0;
  width: 100%;
  height: 50px;
  padding-top: 20px;
  box-sizing: border-box;
  background-color: #fff;
  z-index: 2;
}
.container {
  padding: 5px;
  box-sizing: border-box;
  .box {
    width: 100%;

    background-color: #fff;
    font-size: 14px;
    border-radius: 5px;
    color: #333;
    .title {
      border-bottom: 1px solid #eee;
      padding: 10px;
      box-sizing: border-box;
      &::before {
        content: "";
        display: inline-block;
        width: 4px;
        height: 20px;
        margin-right: 15px;
        background-color: #02a7f0;
        // 和标题对其
        vertical-align: middle;
      }
    }
    .content2 {
      padding: 0px 20px 20px 20px;
      box-sizing: border-box;
    }
    .content {
      padding: 0px 20px;
      box-sizing: border-box;

      .imgTitle {
        margin-top: 10px;
        box-sizing: border-box;
        font-weight: 700;
        padding-bottom: 10px;
        box-sizing: border-box;
        border-bottom: 1px solid #eee;
      }
      .imgbox {
        padding: 10px;
        box-sizing: border-box;
      }

      .fa {
        width: 100%;
        height: 30px;
        line-height: 30px;
        padding-left: 15px;
        box-sizing: border-box;
        background-color: #e3f5fd;
        margin: 10px;
      }
    }
  }
}

.mp {
  margin-top: 15px;
}
.mp10 {
  margin-top: 10px;
}
.pd50 {
  padding: 0 50px;
  box-sizing: border-box;
}
.left10 {
  padding-left: 20px;
  box-sizing: border-box;
}
.tp5 {
  margin-top: 5px;
  width: 148px;
  text-align: center;
  font-weight: 700;
  color: #8c939d;
}
.imgs {
  width: 130px;
  height: 130px;
  margin: 5px;
}
.center {
  width: 600px;
  text-align: center;
  margin: 10px auto;
}

.lable {
  width: 130px;
  display: inline-block;
  text-align: end;
}

.imgBox {
  //width: 148px;
  height: 148px;
  position: relative;
  //margin-bottom: 5px;
}

.imgBox:hover .img-icon {
  display: flex;
}

.imgBox:hover .img-icon {
  display: flex;
}

.img-icon {
  width: 148px;
  height: 148px;
  border-radius: 6px;
  position: absolute;
  left: 0;
  top: 0;
  display: none;
  align-items: center;
  justify-content: center;
  gap: 10px;
  background-color: rgba(0, 0, 0, 0.4);

  i {
    font-size: 26px;
    color: #fff;
  }
}

.avatar-uploader {
  width: 100%;
}

.avatar-uploader ::v-deep .el-upload {
  //background-color: #fbfdff;
  border-radius: 6px;
  cursor: pointer;
  position: relative;
  overflow: hidden;
  display: flex;
  flex-wrap: wrap;
  gap: 10px;
}

.avatar-uploader .el-upload:hover {
  border-color: #409eff;
}

.avatar-uploader-icon {
  border: 1px dashed #c0ccda;
  font-size: 28px;
  color: #8c939d;
  width: 148px;
  height: 148px;
  line-height: 148px;
  text-align: center;
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 99;
}

/deep/.el-loading-spinner {
  margin-top: -74px;
}

.avatar {
  display: block;
  width: 148px;
  height: 148px;
  border-radius: 6px;
}

.imgBox {
  //width: 148px;
  height: 148px;
  position: relative;
  //margin-bottom: 5px;
}

.imgBox:hover .img-icon {
  display: flex;
}

.table {
  .el-button {
    height: 20px !important;
    line-height: 6px !important;
  }
}

::v-deep .el-table--enable-row-hover .el-table__body tr:hover > td {
  background-color: #f59a23 !important;
}
.text {
  text-align: center;
  margin-top: 10px;
}
::v-deep .el-table td {
  padding: 0 !important;
}
::v-deep .el-table th {
  padding: 0px !important;
}
.pdfbox {
  display: flex;

  .item {
    margin: 0 10px;
  }
}
.imgboxs {
  margin-left: 130px;
  margin-top: 5px;
  display: flex;
  & > div {
    margin: 0 10px;
  }
}
::v-deep .el-image {
  margin: 5px !important;
}

// .titlesss::before {
//   content: "*";
//   color: red;
//   position: absolute;
//   left: 2px;
// }
.titlesss {
  color: red;
  display: inline-block;
  margin-right: 3px;
}

.success {
  color: #62a003;
  font-weight: 700;
}

.error {
  color: #d6000a;
  font-weight: 700;
}
.shenhebox {
  height: 500px;
  overflow: auto;
}
.tag {
  width: 80px;
  height: 30px;
  line-height: 30px;
  font-size: 16px;
  text-align: center;
}
</style>
