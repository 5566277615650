import { render, staticRenderFns } from "./material.vue?vue&type=template&id=26355f48&scoped=true"
import script from "./material.vue?vue&type=script&lang=js"
export * from "./material.vue?vue&type=script&lang=js"
import style0 from "./material.vue?vue&type=style&index=0&id=26355f48&prod&lang=less&scoped=true"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "26355f48",
  null
  
)

component.options.__file = "material.vue"
export default component.exports