import { render, staticRenderFns } from "./uploadImgList.vue?vue&type=template&id=4272a8f1&scoped=true"
import script from "./uploadImgList.vue?vue&type=script&lang=js"
export * from "./uploadImgList.vue?vue&type=script&lang=js"
import style0 from "./uploadImgList.vue?vue&type=style&index=0&id=4272a8f1&prod&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "4272a8f1",
  null
  
)

component.options.__file = "uploadImgList.vue"
export default component.exports