<template>
  <div>
    <el-dialog width="80%"  :visible="showDialog" @update:visible="$emit('update:showDialog', $event)">
      <iframe :src="pdfSrc" frameborder="0" width="100%" height="700" ></iframe>
    </el-dialog>
  </div>
</template>

<script>
export default {
  props: {
    pdfSrc: {
      type: String,
      default: ""
    },
    showDialog: {
      type: Boolean,
      default: false
    }
  }
};
</script>

<style>
</style>
