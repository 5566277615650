<template>
  <div class="container bgblue">
    <div class="">
      <div class="box">
        <div class="title">用户信息</div>
        <div class="content2">
          <userInfo :obj="obj"></userInfo>
        </div>
      </div>
      <div class="box mp10">
        <div class="title">勘探基本信息</div>
        <div class="content2">
          <exploration :obj="obj"></exploration>
        </div>
      </div>

      <houses></houses>
      <div class="box mp10">
        <div class="title">现场照片</div>
        <div class="content">
          <div class="pd50">
            <el-row>
              <el-col :span="4" style="position: relative">
                <uploadImg
                  @emitUpload="emitUpload"
                  :list="this.sceneEast"
                  :text="'东'"
                  :type="'scene_east'"
                  :previewImgList="this.sceneEast"
                ></uploadImg>
              </el-col>
              <el-col :span="4" style="position: relative">
                <uploadImg
                  @emitUpload="emitUpload"
                  :list="this.sceneSouth"
                  :text="'南'"
                  :type="'scene_south'"
                  :previewImgList="this.sceneSouth"
                >
                </uploadImg>
              </el-col>
              <el-col :span="4" style="position: relative">
                <uploadImg
                  @emitUpload="emitUpload"
                  :list="this.sceneWest"
                  :text="'西'"
                  :type="'scene_west'"
                  :previewImgList="this.sceneWest"
                ></uploadImg>
              </el-col>
              <el-col :span="4" style="position: relative">
                <uploadImg
                  @emitUpload="emitUpload"
                  :list="this.sceneNorth"
                  :text="'北'"
                  :type="'scene_north'"
                  :previewImgList="this.sceneNorth"
                >
                </uploadImg>
              </el-col>
              <el-col :span="4" style="position: relative">
                <uploadImg
                  @emitUpload="emitUpload"
                  :list="this.sceneSouthEast"
                  :text="'东南'"
                  :type="'scene_southeast'"
                  :previewImgList="this.sceneSouthEast"
                >
                </uploadImg>
              </el-col>
              <el-col :span="4" style="position: relative">
                <uploadImg
                  @emitUpload="emitUpload"
                  :list="this.sceneSouthwest"
                  :text="'西南'"
                  :type="'scene_southwest'"
                  :previewImgList="this.sceneSouthwest"
                >
                </uploadImg>
              </el-col>
            </el-row>
            <el-row>
              <el-col :span="4" style="position: relative">
                <uploadImg
                  @emitUpload="emitUpload"
                  :list="this.sceneInternal"
                  :text="'房屋内部照片'"
                  :type="'scene_internal'"
                  :previewImgList="this.sceneInternal"
                >
                </uploadImg>
              </el-col>
              <el-col :span="4" style="position: relative">
                <uploadImg
                  @emitUpload="emitUpload"
                  :list="this.roofStructure"
                  :text="'房顶内部结构照片'"
                  :type="'roof_structure'"
                  :previewImgList="this.roofStructure"
                >
                </uploadImg>
              </el-col>
            </el-row>
            <!-- <on-site
              :sceneEast="sceneEast"
              :sceneSouth="sceneSouth"
              :sceneWest="sceneWest"
              :sceneNorth="sceneNorth"
              :sceneSouthEast="sceneSouthEast"
              :sceneSouthwest="sceneSouthwest"
              :sceneInternal="sceneInternal"
              :roofStructure="roofStructure"
            ></on-site> -->
          </div>
          <div class="imgTitle left10">
            现场视频（{{ sceneVideo.length }}/2）
          </div>
          <div class="pd50">
            <div class="mp10">周围环境及屋面视频</div>
            <div>
              <el-upload
                class="avatar-uploader"
                :action="$uploadURL"
                :show-file-list="false"
                :on-success="handelSceneVideo"
                :limit="2"
                :before-upload="beforeAvatarUploadVideo"
              >
                <div v-if="sceneVideo.length > 0" class="flex">
                  <div
                    style="margin: 5px"
                    class="imgBox"
                    v-for="(item, index) in sceneVideo"
                    :key="index"
                  >
                    <video :src="item.explorateImgUrl" class="avatar" alt="" />
                    <div class="img-icon">
                      <i
                        class="el-icon-zoom-in"
                        @click.stop="handelVideo(item)"
                      ></i>
                      <i
                        class="el-icon-delete"
                        @click.stop="handelRemoveSceneVideo(item)"
                      ></i>
                    </div>
                  </div>
                </div>
                <i class="el-icon-plus avatar-uploader-icon"></i>
              </el-upload>
              <!-- <video
                :src="item.explorateImgUrl"
                class="imgs"
                v-for="item in sceneVideo"
                :key="item.id"
                controls
              ></video> -->
            </div>
            <div class="mp10">其他照片：</div>
            <div class="mp10">暂无信息</div>
          </div>
        </div>
      </div>
      <div class="box">
        <survey-table></survey-table>
        <!-- <div class="content">
          <div class="imgTitle">现勘表照片（{{ SurveyTable.length }}/10）</div>
          <div class="imgbox">
            <div>现勘表照片：</div>
            <div style="position: relative">
                     <uploadImg
              @emitUpload="emitUpload"
              :list="this.SurveyTable"
              :text="'现勘表照片：'"
              :type="'survey_table'"
              :previewImgList="this.SurveyTable"
            >
            </uploadImg>
              <el-upload
                class="avatar-uploader xin"
                :action="$uploadURL"
                :show-file-list="false"
                :on-success="handelSurveyTable"
                :before-upload="beforeAvatarUpload"
              >
                <div v-if="SurveyTable.length > 0" class="flex">
                  <div
                    style="margin: 5px"
                    class="imgBox"
                    v-for="(item, index) in SurveyTable"
                    :key="index"
                  >
                    <img :src="item.explorateImgUrl" class="avatar" alt="" />
                    <div class="img-icon">
                      <i
                        class="el-icon-zoom-in"
                        @click.stop="onPreview(item)"
                      ></i>
                      <i
                        class="el-icon-delete"
                        @click.stop="handelRemoveSurveyTable(item)"
                      ></i>
                    </div>
                  </div>
                </div>
                <i class="el-icon-plus avatar-uploader-icon"></i>
              </el-upload>
            </div>
            <div>暂无信息</div>
          </div>
        </div> -->
      </div>

      <div class="box mp10">
        <div class="title">设计图纸</div>
        <div class="content">
          <div class="flex" style="padding: 15px 50px">
            <div>设计图纸文件：</div>
            <div class="pdfbox">
              <div class="item" style="position: relative">
                <el-upload
                  class="avatar-uploader xin"
                  :action="$uploadURL"
                  :show-file-list="false"
                  :on-success="handelCoverSuccessPM"
                >
                  <div v-if="pdfSrcPM" class="imgBox">
                    <img src="@/assets/images/PDF.png" class="avatar" alt="" />
                    <div class="img-icon">
                      <i
                        class="el-icon-zoom-in"
                        @click.stop="handelImageCoverPreview('pm')"
                      ></i>
                      <i
                        class="el-icon-delete"
                        @click.stop="handelImageCoverRemove('pm')"
                      ></i>
                    </div>
                  </div>
                  <i v-else class="el-icon-plus avatar-uploader-icon"></i>
                </el-upload>
                <div class="text">
                  <span class="titlesss">*</span>请上传平面文件
                </div>
              </div>
              <div class="item" style="position: relative">
                <el-upload
                  class="avatar-uploader xin"
                  :action="$uploadURL"
                  :show-file-list="false"
                  :on-success="handelCoverSuccessZJ"
                >
                  <div v-if="pdfSrcZJ" class="imgBox">
                    <img src="@/assets/images/PDF.png" class="avatar" alt="" />
                    <div class="img-icon">
                      <i
                        class="el-icon-zoom-in"
                        @click.stop="handelImageCoverPreview('zj')"
                      ></i>
                      <i
                        class="el-icon-delete"
                        @click.stop="handelImageCoverRemove('zj')"
                      ></i>
                    </div>
                  </div>
                  <i v-else class="el-icon-plus avatar-uploader-icon"></i>
                </el-upload>
                <div class="text">
                  <span class="titlesss">*</span>请上传支架测试图平面文件
                </div>
              </div>
              <div class="item" style="position: relative">
                <el-upload
                  class="avatar-uploader xin"
                  :action="$uploadURL"
                  :show-file-list="false"
                  :on-success="handelCoverSuccessZX"
                >
                  <div v-if="pdfSrcZX" class="imgBox">
                    <img src="@/assets/images/PDF.png" class="avatar" alt="" />
                    <div class="img-icon">
                      <i
                        class="el-icon-zoom-in"
                        @click.stop="handelImageCoverPreview('zx')"
                      ></i>
                      <i
                        class="el-icon-delete"
                        @click.stop="handelImageCoverRemove('zx')"
                      ></i>
                    </div>
                  </div>
                  <i v-else class="el-icon-plus avatar-uploader-icon"></i>
                </el-upload>
                <div class="text">
                  <span class="titlesss">*</span>请上传走线图文件
                </div>
              </div>
              <div class="item" style="position: relative">
                <el-upload
                  class="avatar-uploader xin"
                  :action="$uploadURL"
                  :show-file-list="false"
                  :on-success="handelCoverSuccessOne"
                >
                  <div v-if="pdfSrcOne" class="imgBox">
                    <img src="@/assets/images/PDF.png" class="avatar" alt="" />
                    <div class="img-icon">
                      <i
                        class="el-icon-zoom-in"
                        @click.stop="handelImageCoverPreview('one')"
                      ></i>
                      <i
                        class="el-icon-delete"
                        @click.stop="handelImageCoverRemove('one')"
                      ></i>
                    </div>
                  </div>
                  <i v-else class="el-icon-plus avatar-uploader-icon"></i>
                </el-upload>
                <div class="text">
                  <span class="titlesss">*</span>请上传一次性连接图文件
                </div>
              </div>
              <div class="item">
                <el-upload
                  class="avatar-uploader"
                  :action="$uploadURL"
                  :show-file-list="false"
                  :on-success="handelCoverSuccessQT"
                >
                  <div v-if="pdfSrcQT" class="imgBox">
                    <img src="@/assets/images/PDF.png" class="avatar" alt="" />
                    <div class="img-icon">
                      <i
                        class="el-icon-zoom-in"
                        @click.stop="handelImageCoverPreview('qt')"
                      ></i>
                      <i
                        class="el-icon-delete"
                        @click.stop="handelImageCoverRemove('qt')"
                      ></i>
                    </div>
                  </div>
                  <i v-else class="el-icon-plus avatar-uploader-icon"></i>
                </el-upload>
                <div class="text">其他</div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="box mp10">
        <div class="title">选择物料</div>
        <div class="content" style="padding: 20px; box-sizing: border-box">
          <el-button type="primary" @click="addMaterial">选择物料</el-button>
          <el-table :data="materialList" border>
            <el-table-column
              type="index"
              width="55"
              label="序号"
              align="center"
            ></el-table-column>
            <el-table-column
              label="物料名称"
              prop="product_name"
              align="center"
            />
            <el-table-column label="规格型号" prop="specs" align="center" />
            <el-table-column label="品牌" prop="brand" align="center" />
            <el-table-column label="数量/台数" prop="conte" align="center">
              <template v-slot="{ row }">
                <el-input v-model="row.conte" style="width: 100px"></el-input>
              </template>
            </el-table-column>
            <el-table-column label="单位" prop="unit" align="center" />
            <el-table-column fixed="right" label="操作" width="80">
              <template slot-scope="scope">
                <el-button
                  style="margin-left: 50%; transform: translateX(-50%)"
                  @click.native.prevent="deleteRow(scope.$index, materialList)"
                  type="primary"
                  size="small"
                >
                  移除
                </el-button>
              </template>
            </el-table-column>
            <!-- <el-table-column label="备注" prop="name" align="center" /> -->
          </el-table>
        </div>
      </div>
      <div class="box mp10">
        <div class="title">备注</div>
        <div class="content" style="padding: 15px 50px; box-sizing: border-box">
          <!-- 房屋高11米，现浇顶，顶板厚度25公分，阳光棚7排，南4北3，南北出墙70公分，东西不出墙，施工前，彩钢瓦移除，无其他遮挡 -->
          {{ obj.scheme ? obj.scheme.remark : "" }}
        </div>
      </div>
      <div class="box mp10">
        <div class="title">审核记录</div>
        <div class="content table">
          <el-table
            :data="tableData"
            :header-cell-style="{ background: '#f2f2f2', color: '#333' }"
            border
          >
            <el-table-column label="操作" align="center">
              <template v-slot="{ row }">
                <el-button
                  type="primary"
                  size="mini"
                  class="btn-blue"
                  @click="handelDetail(row)"
                  >查看详情</el-button
                >
              </template>
            </el-table-column>
            <el-table-column
              label="提交人"
              prop="createName"
              align="center"
            ></el-table-column>
            <el-table-column
              label="提交时间"
              prop="createDate"
              align="center"
            ></el-table-column>

            <el-table-column
              label="技术审核人"
              prop="techcreateName"
              align="center"
            ></el-table-column>
            <el-table-column
              label="技术审核时间"
              prop="techcreateDate"
              align="center"
            ></el-table-column>
            <el-table-column
              label="商务审核人"
              prop="businesscreateName"
              align="center"
            ></el-table-column>
            <el-table-column
              label="商务审核时间"
              prop="businesscreateDate"
              align="center"
            ></el-table-column>
            <el-table-column label="审核状态" prop="nodeStatus" align="center">
              <template v-slot="{ row }">
                <el-button
                  v-if="row.nodeStatus == 0"
                  type="text"
                  class="btn-red"
                  >未通过</el-button
                >
                <el-button
                  v-if="row.nodeStatus == 1"
                  type="text"
                  class="btn-green"
                  >已完成</el-button
                >
              </template>
            </el-table-column>
          </el-table>
        </div>
        <div style="height: 100px"></div>
      </div>
    </div>
    <div class="fixed">
      <el-row type="flex" justify="center">
        <el-button @click="$router.back()">返&emsp;回</el-button>
        <el-button type="primary" @click="save">保&emsp;存</el-button>
        <el-button type="primary" @click="submit">保存并提交审核</el-button>
      </el-row>
    </div>
    <dialogPdf :pdfSrc="pdfSrcPM" :showDialog.sync="dialogVisiblePM" />
    <dialogPdf :pdfSrc="pdfSrcZX" :showDialog.sync="dialogVisibleZX" />
    <dialogPdf :pdfSrc="pdfSrcZJ" :showDialog.sync="dialogVisibleZJ" />
    <dialogPdf :pdfSrc="pdfSrcOne" :showDialog.sync="dialogVisibleOne" />
    <dialogPdf :pdfSrc="pdfSrcQT" :showDialog.sync="dialogVisibleQT" />

    <el-dialog title="查看审核详情" :visible.sync="detailDialog">
      <div class="title">
        <el-row>
          <el-col :span="12">
            <span style="color: #333; font-weight: 700">审核结果：</span>
            <el-tag type="danger" class="tag" v-if="act.nodeStatus == 0"
              >不通过</el-tag
            >
            <el-tag type="success" class="tag" v-if="act.nodeStatus == 1"
              >通过</el-tag
            >
            <el-tag type="primary" class="tag" v-if="act.nodeStatus == 2"
              >审核中</el-tag
            >
          </el-col>
          <el-col :span="12">
            <span
              ><span style="color: #333; font-weight: 700">审核日期：</span
              >{{ act.createDate }}</span
            >
          </el-col>
        </el-row>
      </div>
      <div class="shenhebox">
        <!-- 基本信息 -->
        <div class="mp10" v-if="act.businesscreateName">
          <el-row>
            <el-col :span="12">
              <span style="color: #333; font-weight: 700">商务审核人：</span>
              {{ act.businesscreateName }}</el-col
            >
            <el-col :span="12">
              <span style="color: #333; font-weight: 700">商务审核时间：</span>
              {{ act.businesscreateDate }}</el-col
            >
          </el-row>
        </div>
        <div style="margin-top: 10px; display: flex">
          <span style="color: #333; font-weight: 700">基本信息</span>审核结果:
          <div
            v-if="act.bussinessProcessList.length == 1"
            style="margin-left: 15px"
          >
            <span
              v-if="act.bussinessProcessList[0].processResult == 1"
              class="success"
              >[通过]</span
            >
            <span
              v-if="act.bussinessProcessList[0].processResult == 0"
              class="error"
              >[不通过]</span
            >
            <div
              v-for="(item, index) in act.bussinessProcessList[0].commentList"
              :key="index"
            >
              <div class="mp10">审批意见{{ index + 1 }}</div>
              <div style="margin-top: 5px">1、资料补全：{{ item.comment }}</div>
              <div style="margin-top: 5px">2、房屋信息补全</div>
              <div class="flex" v-if="item.commentImgList.length != 0">
                <div v-for="ele in item.commentImgList" :key="ele">
                  <el-image
                    v-if="ele.commentImgUrl"
                    style="width: 130px; height: 130px; margin: 10px"
                    :src="ele.commentImgUrl"
                    :preview-src-list="[...ele.commentImgUrl]"
                  >
                  </el-image>
                </div>
              </div>
            </div>
          </div>
        </div>
        <!-- 房屋信息 -->
        <div class="mp10" v-if="act.businesscreateName">
          <el-row>
            <el-col :span="12">
              <span style="color: #333; font-weight: 700">技术审核人：</span>
              {{ act.techcreateName }}</el-col
            >
            <el-col :span="12">
              <span style="color: #333; font-weight: 700">技术审核时间：</span>
              {{ act.techcreateDate }}</el-col
            >
          </el-row>
        </div>
        <div style="margin-top: 10px; display: flex">
          <span style="color: #333; font-weight: 700">房屋信息</span>审核结果:
          <div v-if="act.techProcessList.length >= 1" style="margin-left: 15px">
            <span
              v-if="act.techProcessList[0].processResult == 1"
              class="success"
              >[通过]</span
            >
            <span v-if="act.techProcessList[0].processResult == 0" class="error"
              >[不通过]</span
            >
            <div
              v-for="(item, index) in act.techProcessList[0].commentList"
              :key="item.id"
            >
              <div class="mp10">审批意见{{ index + 1 }}</div>
              <div style="margin-top: 5px">1、资料补全：{{ item.comment }}</div>
              <div style="margin-top: 5px">2、房屋信息补全</div>
              <div class="flex">
                <div v-for="ele in item.commentImgList" :key="ele.id">
                  <el-image
                    v-if="ele.commentImgUrl"
                    style="width: 130px; height: 130px; margin: 10px"
                    :src="ele.commentImgUrl"
                    :preview-src-list="[...ele.commentImgUrl]"
                  >
                  </el-image>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div style="margin-top: 10px; display: flex">
          <span style="color: #333; font-weight: 700">方案信息</span>审核结果:
          <div v-if="act.techProcessList.length == 2" style="margin-left: 15px">
            <span
              v-if="act.techProcessList[1].processResult == 1"
              class="success"
              >[通过]</span
            >
            <span v-if="act.techProcessList[1].processResult == 0" class="error"
              >[不通过]</span
            >
            <div
              v-for="(item, index) in act.techProcessList[1].commentList"
              :key="item.id"
            >
              <div class="mp10">审批意见{{ index + 1 }}</div>
              <div style="margin-top: 5px">1、资料补全：{{ item.comment }}</div>
              <div style="margin-top: 5px">2、房屋信息补全</div>
              <div class="flex">
                <div v-for="ele in item.commentImgList" :key="ele.id">
                  <el-image
                    v-if="ele.commentImgUrl"
                    style="width: 130px; height: 130px; margin: 10px"
                    :src="ele.commentImgUrl"
                    :preview-src-list="[...ele.commentImgUrl]"
                  >
                  </el-image>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </el-dialog>
    <!-- <el-dialog title="图片" :visible.sync="dialogVisibleImg" width="35%">
      <img width="100%" :src="dialogImageUrl" alt="" class="bigImg" />
    </el-dialog> -->
    <el-dialog title="视频" :visible.sync="dialogVisibleVideo" width="35%">
      <video
        width="100%"
        :src="dialogVideoUrl"
        alt=""
        class="bigImg"
        controls
      />
    </el-dialog>
    <material ref="materialRef" @toMaterial="getMaterial"></material>
    <el-image-viewer
      v-if="showViewer"
      :zIndex="8000"
      :on-close="closeViewer"
      :url-list="previewImgList"
      :initial-index="preIniIndex"
    />
  </div>
</template>

<script>
import material from "../components/material.vue";
import dialogPdf from "../components/dialogPdf.vue";
import { add, remove, removePreviewImgList } from "../common";
import uploadImg from "./components/uploadImg.vue";

import ElImageViewer from "element-ui/packages/image/src/image-viewer";
import userInfo from "./components/userInfo.vue";
import exploration from "./components/exploration.vue";
import Houses from "./components/houses.vue";
import OnSite from "./components/onSite.vue";
import SurveyTable from './components/surveyTable.vue';

export default {
  components: {
    material,
    dialogPdf,
    uploadImg,
    ElImageViewer,
    userInfo,
    exploration,
    Houses,
    OnSite,
    SurveyTable,
  },
  data() {
    return {
      fileList: [],
      pdfSrcPM: "",
      pdfSrcZJ: "",
      pdfSrcZX: "",
      pdfSrcOne: "",
      pdfSrcQT: "",
      dialogVisiblePM: false,
      dialogVisibleZX: false,
      dialogVisibleZJ: false,
      dialogVisibleOne: false,
      dialogVisibleQT: false,
      dialogVisibleImg: false,
      detailDialog: false,
      disabled: false,
      action: "https://house.zhongliangxny.com/api/upload/uploadFile",
      hideUploadEdit: false,
      tableData: [{}, {}, {}, {}],
      dialogImageUrl: "",
      dialogVisibleVideo: false, // 视频弹框
      dialogVideoUrl: "",
      obj: {
        scheme: {},
        customer: {},
      },
      mainGate: [], //正大门
      fullView: [], //  屋顶全景
      mainRoof: [], // 主房屋屋顶
      azimuth: [], // 奥维定位
      sceneEast: [], //  东
      sceneSouthEast: [], // 东南
      sceneSouth: [], // 南
      sceneSouthwest: [], //西南
      sceneWest: [], // 西
      sceneNorth: [], // 北
      sceneSouthfacade: [], // 南立面
      sceneNorthfacade: [], //北立面
      sceneInternal: [], // 内部
      roofStructure: [], // 屋顶
      sceneVideo: [], // 现场视频
      drawingList: [], //设计图纸
      // handelSurveyTable:[],
      explorateCode: "",
      pdfList: [],
      disabled: false,
      fileList: [], //本地删除文件列表
      imgList: [],
      previewListFicateImg1: [], //预览身份证正面
      previewListFicateImg2: [], //预览身份证正面
      previewListBankcardImg1: [], //银行卡正面
      previewListBankcardImg2: [], //银行卡正面
      previewListPropertyInfo: [], // 房屋信息
      SurveyTable: [], // 现堪
      act: {
        bussinessProcessList: [],
        techProcessList: [],
      },
      customerImg: {
        certificateImg1: "",
        certificateImg2: "",
        bankcardImg1: "",
        bankcardImg2: "",
        propertyInfo: "",
        corporateAccount: "",
        businessLicense: "",
      },
      materialList: [],
      preIniIndex: 0, // 初始化展示哪张图片
      showViewer: false, // 显示查看器
      previewImgList: [], // 预览图片列表
    };
  },
  methods: {
    //查看大图
    onPreview(item) {
      // console.log(this.previewImgList, 1266);
      const index = this.previewImgList.indexOf(item);
      console.log(index, 1268);
      this.showPreviewImg(this.previewImgList[index]);
      this.showViewer = true;
    },

    // 关闭查看器
    closeViewer() {
      this.showViewer = false;
    },
    // 设置点击哪张图预览哪张图片方法
    showPreviewImg(url) {
      let previewImgList = this.previewImgList;
      for (let index in previewImgList) {
        if (previewImgList[index] === url) {
          this.preIniIndex = Number(index);
          break;
        }
      }
      //console.log('触发了吗')
      this.showViewer = true;
    },

    getMaterial(val) {
      console.log(val);
      const filteredVal = val.filter((ele) => {
        return !this.materialList.some(
          (item) =>
            item.brand === ele.brand &&
            item.product_name === ele.product_name &&
            item.specs === ele.specs
        );
      });
      if (filteredVal.length == 0) {
        this.$message({
          type: "warning", // success error warning
          message: "物料已存在",
          duration: 2000,
        });
      }
      this.materialList.push(...filteredVal);
    },

    // 物料信息
    addMaterial() {
      this.$refs.materialRef.init();
    },
    handelCoverSuccessPM(response, file, fileList) {
      this.pdfSrcPM = response.data.url;
    },
    handelCoverSuccessZX(response, file, fileList) {
      this.pdfSrcZX = response.data.url;
    },
    handelCoverSuccessZJ(response, file, fileList) {
      this.pdfSrcZJ = response.data.url;
    },
    handelCoverSuccessOne(response, file, fileList) {
      this.pdfSrcOne = response.data.url;
    },
    handelCoverSuccessQT(response, file, fileList) {
      this.pdfSrcQT = response.data.url;
    },
    // 查看pdf
    handelImageCoverPreview(val) {
      if (val === "pm") {
        this.dialogVisiblePM = true;
      } else if (val === "zj") {
        this.dialogVisibleZJ = true;
      } else if (val === "zx") {
        this.dialogVisibleZX = true;
      } else if (val === "one") {
        this.dialogVisibleOne = true;
      } else {
        this.dialogVisibleQT = true;
      }
    },
    // 删除
    handelImageCoverRemove(val) {
      if (val === "pm") {
        this.pdfSrcPM = "";
      } else if (val === "zj") {
        this.pdfSrcZJ = "";
      } else if (val === "zx") {
        this.pdfSrcZX = "";
      } else if (val === "one") {
        this.pdfSrcOne = "";
      } else {
        this.pdfSrcQT = "";
      }
    },
    // // 查看大图
    // handelImage(item) {
    //   console.log(item, 981);
    //   this.dialogVisibleImg = true;
    //   if (item.explorateImgUrl) {
    //     this.dialogImageUrl = item.explorateImgUrl;
    //   } else {
    //     this.dialogImageUrl = item;
    //   }
    // },

    handelDetail(row) {
      this.$http
        .post("/houseExplorateExamineNode/queryOneNode", {
          nodeCode: row.nodeCode,
        })
        .then((res) => {
          console.log(res, 999);
          if (res.data.code == 200) {
            this.act = res.data.data;
          }
        });
      this.detailDialog = true;
    },
    getData() {
      this.$http
        .post("/houseExplorateBase/detail", {
          explorateCode: this.explorateCode,
        })
        .then((res) => {
          if (res.data.code === 200) {
            this.obj = res.data.data;
            this.obj.materialList.forEach((item) => {
              item.product_name = item.productName;
            });

            this.materialList = this.obj.materialList;
            // 大门
            this.mainGate = this.obj.houseimgList.filter(
              (item) => item.explorateImgType == "main_gate"
            );

            this.$store.commit("expoleManageDetail/setList", {
              type: "main_gate",
              value: this.mainGate,
            });

            //屋顶全景
            this.fullView = this.obj.houseimgList.filter(
              (item) => item.explorateImgType == "full_view"
            );
            this.$store.commit("expoleManageDetail/setList", {
              type: "full_view",
              value: this.fullView,
            });
            //主房屋顶
            this.mainRoof = this.obj.houseimgList.filter(
              (item) => item.explorateImgType == "main_roof"
            );
            this.$store.commit("expoleManageDetail/setList", {
              type: "main_roof",
              value: this.mainRoof,
            });
            //奥维定位
            this.azimuth = this.obj.houseimgList.filter(
              (item) => item.explorateImgType == "azimuth"
            );
            this.$store.commit("expoleManageDetail/setList", {
              type: "azimuth",
              value: this.azimuth,
            });
            // 东
            this.sceneEast = this.obj.houseimgList.filter(
              (item) => item.explorateImgType == "scene_east"
            );
            // 东南
            this.sceneSouthEast = this.obj.houseimgList.filter(
              (item) => item.explorateImgType == "scene_southeast"
            );
            // 南
            this.sceneSouth = this.obj.houseimgList.filter(
              (item) => item.explorateImgType == "scene_south"
            );
            // 西南
            this.sceneSouthwest = this.obj.houseimgList.filter(
              (item) => item.explorateImgType == "scene_southwest"
            );
            // 西
            this.sceneWest = this.obj.houseimgList.filter(
              (item) => item.explorateImgType == "scene_west"
            );
            // 北
            this.sceneNorth = this.obj.houseimgList.filter(
              (item) => item.explorateImgType == "scene_north"
            );
            // 南立面
            this.sceneSouthfacade = this.obj.houseimgList.filter(
              (item) => item.explorateImgType == "scene_southfacade"
            );
            // 北立面
            this.sceneNorthfacade = this.obj.houseimgList.filter(
              (item) => item.explorateImgType == "scene_northfacade"
            );
            // 内部
            this.sceneInternal = this.obj.houseimgList.filter(
              (item) => item.explorateImgType == "scene_internal"
            );
            // 屋顶结构照
            this.roofStructure = this.obj.houseimgList.filter(
              (item) => item.explorateImgType == "roof_structure"
            );
            // 现场视频
            this.sceneVideo = this.obj.houseimgList.filter(
              (item) => item.explorateImgType == "scene_video"
            );
            // 现刊表
            this.SurveyTable = this.obj.houseimgList.filter(
              (item) => item.explorateImgType == "survey_table"
            );
            this.$store.commit("expoleManageDetail/setList", {
              type: "survey_table",
              value: this.SurveyTable,
            });

            this.tableData = this.obj.nodeList;

            this.pdfList = this.obj.drawingList;
            this.pdfList.forEach((item) => {
              if (item.drawingName == "平面图") {
                this.pdfSrcPM = item.drawingUrl;
              } else if (item.drawingName == "支架测试图") {
                this.pdfSrcZJ = item.drawingUrl;
              } else if (item.drawingName == "走线图") {
                this.pdfSrcZX = item.drawingUrl;
              } else if (item.drawingName == "一次性连接图") {
                this.pdfSrcOne = item.drawingUrl;
              } else {
                this.pdfSrcQT = item.drawingUrl;
              }
            });
            this.customerImg.certificateImg1 =
              this.obj.customer.certificateImg1;
            this.customerImg.certificateImg2 =
              this.obj.customer.certificateImg2;
            this.customerImg.bankcardImg1 = this.obj.customer.bankcardImg1;
            this.customerImg.bankcardImg2 = this.obj.customer.bankcardImg2;
            this.customerImg.propertyInfo = this.obj.customer.propertyInfo;
            this.customerImg.corporateAccount =
              this.obj.customer.corporateAccount;
            this.customerImg.businessLicense =
              this.obj.customer.businessLicense;

            this.$store.commit("expoleManageDetail/setImgs", this.customerImg);

            // 筛选出png格式的图片
            let arr = this.obj.houseimgList
              .filter((item) => {
                return (
                  item.explorateImgUrl && item.explorateImgType != "scene_video"
                );
              })
              .map((item) => item.explorateImgUrl);
            let customerImg = [
              ...Object.values(this.customerImg).filter((item) => !!item),
            ];

            // 大图预览
            this.previewImgList = [...arr, ...customerImg];
          } else {
            this.$message({
              type: "error",
              message: res.data.message,
              duration: 2000,
            });
          }
        });
    },
    //图片校验
    beforeAvatarUpload(file) {
      const ImageType =
        file.type === "image/jpeg" ||
        file.type === "image/png" ||
        file.type === "image/jpg";
      if (!ImageType) {
        this.$message.error("上传图片只能是 JPG 、PNG 、JPEG 格式!");
      }
      return ImageType;
    },
    save() {
      let schemeList = [
        {
          drawingName: "平面图",
          drawingUrl: this.pdfSrcPM,
        },
        {
          drawingName: "支架测试图",
          drawingUrl: this.pdfSrcZJ,
        },
        {
          drawingName: "走线图",
          drawingUrl: this.pdfSrcZX,
        },
        {
          drawingName: "一次性连接图",
          drawingUrl: this.pdfSrcOne,
        },
        {
          drawingName: "其他",
          drawingUrl: this.pdfSrcQT,
        },
      ];
      let data = {
        houseimgList: [
          ...this.$store.state.expoleManageDetail.mainGate,
          ...this.$store.state.expoleManageDetail.fullView,
          ...this.$store.state.expoleManageDetail.mainRoof,
          ...this.$store.state.expoleManageDetail.azimuth,
          ...this.sceneEast,
          ...this.sceneSouthEast,
          ...this.sceneSouth,
          ...this.sceneSouthwest,
          ...this.sceneWest,
          ...this.sceneNorth,
          ...this.sceneInternal,
          ...this.roofStructure,
          ...this.sceneVideo,
          ...this.$store.state.expoleManageDetail.surveyTable
        ],
        explorateCode: this.explorateCode,
        schemeList,
        HouseCustomerList: this.$store.state.expoleManageDetail.imgs,
        materialList: this.materialList,
      };
      this.$http.post("/houseExplorateBase/editImg", data).then((res) => {
        if (res.data.code === 200) {
          this.$message.success("保存成功");
          this.$router.back();
        } else {
          this.$message({
            type: "error",
            message: res.data.message,
            duration: 2000,
          });
        }
      });
    },
    submit() {
      let schemeList = [
        {
          drawingName: "平面图",
          drawingUrl: this.pdfSrcPM,
        },
        {
          drawingName: "支架测试图",
          drawingUrl: this.pdfSrcZJ,
        },
        {
          drawingName: "走线图",
          drawingUrl: this.pdfSrcZX,
        },
        {
          drawingName: "一次性连接图",
          drawingUrl: this.pdfSrcOne,
        },
        {
          drawingName: "其他",
          drawingUrl: this.pdfSrcQT,
        },
      ];
      let data = {
     houseimgList: [
          ...this.$store.state.expoleManageDetail.mainGate,
          ...this.$store.state.expoleManageDetail.fullView,
          ...this.$store.state.expoleManageDetail.mainRoof,
          ...this.$store.state.expoleManageDetail.azimuth,
          ...this.sceneEast,
          ...this.sceneSouthEast,
          ...this.sceneSouth,
          ...this.sceneSouthwest,
          ...this.sceneWest,
          ...this.sceneNorth,
          ...this.sceneInternal,
          ...this.roofStructure,
          ...this.sceneVideo,
          ...this.$store.state.expoleManageDetail.surveyTable
        ],
        explorateCode: this.explorateCode,
        schemeList,
        customerCode: this.obj.customer.customerCode,
        operateCode: JSON.parse(localStorage.getItem("userInfo")).userInfo
          .userCode,
        operateName: JSON.parse(localStorage.getItem("userInfo")).userInfo.name,
        HouseCustomerList: this.customerImg,
        materialList: this.materialList,
      };
      if (this.customerImg.certificateImg1 == "")
        return this.$message.error("请上传身份证正面照片");
      if (this.customerImg.certificateImg2 == "")
        return this.$message.error("请上传身份证背面照片");
      if (this.customerImg.bankcardImg1 == "")
        return this.$message.error("请上传银行卡正面照片");
      // if (this.customerImg.bankcardImg2 == "")
      //   return this.$message.error("请上传银行卡背面照片");
      if (this.customerImg.propertyInfo == "")
        return this.$message.error("请上传产权证照片");
      if (
        this.customerImg.corporateAccount == " " &&
        this.obj.customer.customerType == 3
      )
        return this.$message.error("请上传对公账户照片");
      if (
        this.customerImg.businessLicense == " " &&
        this.obj.customer.customerType == 3
      )
        return this.$message.error("请上传营业执照照片");
      if (this.$store.state.expoleManageDetail.mainGate.length == 0)
        return this.$message.error("请上传正大门图片");
      if (this.$store.state.expoleManageDetail.fullView.length == 0)
        return this.$message.error("请上传房屋全景图片");
      if (this.$store.state.expoleManageDetail.mainRoof.length == 0)
        return this.$message.error("请上传屋顶图片");
      if (this.$store.state.expoleManageDetail.azimuth.length == 0)
        return this.$message.error("请上传奥维定位图片");
      if (this.sceneEast.length == 0)
        return this.$message.error("请上传东面图片");
      if (this.sceneSouthEast.length == 0)
        return this.$message.error("请上传东南面图片");
      if (this.sceneSouth.length == 0)
        return this.$message.error("请上传南面图片");
      if (this.sceneSouthwest.length == 0)
        return this.$message.error("请上传西南图片");
      if (this.sceneNorth.length == 0)
        return this.$message.error("请上传北面图片");
      if (this.sceneInternal.length == 0)
        return this.$message.error("请上传房屋内部结构图片");
      if (this.roofStructure.length == 0)
        return this.$message.error("请上传屋顶结构照");
      if (this.pdfSrcPM == "") return this.$message.error("请上传平面文件");
      if (this.pdfSrcZJ == "")
        return this.$message.error("请上传支架测试图文件");
      if (this.pdfSrcZX == "") return this.$message.error("请上传走线图文件");
      if (this.pdfSrcOne == "")
        return this.$message.error("请上传一次性连接图文件");
      if (this.materialList.length == 0)
        return this.$message.error("请选择物料信息");
      if(this.$store.state.expoleManageDetail.surveyTable.length == 0){
        return this.$message.error("请上传现勘表照片");
      }
      this.$http.post("/houseExplorateBase/submitEditImg", data).then((res) => {
        if (res.data.code === 200) {
          this.$message.success("提交成功");
          this.$router.back();

        } else {
          this.$message({
            type: "error",
            message: res.data.message,
            duration: 2000,
          });
        }
      });
    },
    // 正大门
    handelMainGate(response, file, fileList) {
      this.mainGate = add("main_gate", response.data.url, this.mainGate);
    },
    // 删除正大门
    handelRemoveMainGate(item) {
      remove(item.explorateImgUrl, this.mainGate);
      this.previewImgList.splice(
        this.previewImgList.indexOf(item.explorateImgUrl),
        1
      );

      console.log(this.previewImgList, 1815);
    },

    // 屋顶全景
    handelFullView(response, file, fileList) {
      this.fullView = add("full_view", response.data.url, this.fullView);
    },
    // 删除屋顶全景
    handelRemoveFullView(item) {
      remove(item.explorateImgUrl, this.fullView);
    },
    // 主房屋屋顶
    handelMainRoof(response, file, fileList) {
      this.mainRoof = add("main_roof", response.data.url, this.mainRoof);
    },
    handelRemoveMainRoof(item) {
      remove(item.explorateImgUrl, this.mainRoof);
    },
    // 奥维定位
    handelAzimuth(response, file, fileList) {
      this.azimuth = add("azimuth", response.data.url, this.azimuth);
    },
    handelRemoveAzimuth(item) {
      remove(item.explorateImgUrl, this.azimuth);
    },
    //产权证
    handelProperty(response) {
      this.customerImg.propertyInfo = response.data.url;
    },
    handelRemoveProperty() {
      this.customerImg.propertyInfo = "";
    },
    // 身份证背面
    handelCertificateImg2(response) {
      this.customerImg.certificateImg2 = response.data.url;
    },
    handelRemoveCertificateImg2(item) {
      this.customerImg.certificateImg2 = "";
    },
    // 身份证正面
    handelCertificateImg1(response) {
      this.customerImg.certificateImg1 = response.data.url;
    },
    handelRemoveCertificateImg1(item) {
      this.customerImg.certificateImg1 = "";
    },
    // 银行卡正面
    handelbankcardImg1(response) {
      this.customerImg.bankcardImg1 = response.data.url;
    },
    handelRemovebankcardImg1() {
      this.customerImg.bankcardImg1 = "";
    },
    // 银行卡背面
    handelbankcardImg2(response) {
      this.customerImg.bankcardImg2 = response.data.url;
    },
    handelRemovebankcardImg2() {
      this.customerImg.bankcardImg2 = "";
    },
    // 视频
    handelSceneVideo(response) {
      this.sceneVideo = add("scene_video", response.data.url, this.sceneVideo);
    },
    handelRemoveSceneVideo(item) {
      remove(item.explorateImgUrl, this.sceneVideo);
    },
    handelVideo(item) {
      console.log(item, 1886);
      item.explorateImgUrl
        ? (this.dialogVideoUrl = item.explorateImgUrl)
        : (this.dialogVideoUrl = item);
      this.dialogVisibleVideo = true;
      // this.dialogVideoUrl = item.explorateImgUrl;
    },
    // 现堪表
    handelSurveyTable(response) {
      this.SurveyTable = add(
        "survey_table",
        response.data.url,
        this.SurveyTable
      );
    },
    handelRemoveSurveyTable(item) {
      remove(item.explorateImgUrl, this.SurveyTable);
    },

    // 表格删除
    deleteRow(index, rows) {
      console.log(rows, "uuuuuuuuuuuuu");
      rows.splice(index, 1);
    },
    beforeAvatarUploadVideo(file) {
      console.log(file.type, "video");
      const VideoType =
        file.type === "video/mp4" ||
        file.type === "video/avi" ||
        file.type === "video/wmv" ||
        file.type === "video/rmvb";

      if (!VideoType) {
        this.$message.error("上传视频只能是 MP4 、AVI 、WMV 、RMVB 格式!");
      }
      return VideoType;
    },
    emitUpload(val) {
      console.log(val, "emitUpload");
      if (val[0].explorateImgType == "scene_southeast") {
        this.sceneSouthEast.push(...val);
      } else if (val[0].explorateImgType == "scene_east") {
        this.sceneEast.push(...val);
      } else if (val[0].explorateImgType == "scene_south") {
        this.sceneSouth.push(...val);
      } else if (val[0].explorateImgType == "scene_southwest") {
        this.sceneSouthwest.push(...val);
      } else if (val[0].explorateImgType == "scene_west") {
        this.sceneWest.push(...val);
      } else if (val[0].explorateImgType == "scene_north") {
        this.sceneNorth.push(...val);
      } else if (val[0].explorateImgType == "scene_southfacade") {
        this.sceneSouthfacade.push(...val);
      } else if (val[0].explorateImgType == "scene_northfacade") {
        this.sceneNorthfacade.push(...val);
      } else if (val[0].explorateImgType == "scene_internal") {
        this.sceneInternal.push(...val);
      } else if (val[0].explorateImgType == "roof_structure") {
        this.roofStructure.push(...val);
      }
    },
    handelCorporateAccount(response) {
      this.customerImg.corporateAccount = response.data.url;
    },
    handelRemoveCorporateAccount() {
      console.log(111);
      this.customerImg.corporateAccount = "";
    },
    handelBusinessLicense(response) {
      this.customerImg.businessLicense = response.data.url;
    },
    handelRemoveBusinessLicense() {
      this.customerImg.businessLicense = "";
    },
  },

  created() {
    console.log(this.$uploadURL, "oooooooooooooo");
    this.explorateCode = this.$route.query.explorateCode;
    this.getData();
  },
};
</script>

<style lang="scss" scoped>
::v-deep .el-dialog__body {
  padding-top: 0 !important;
}

.imgss {
  margin: 5px;
}

.bigImg {
  width: 500px;
}

.fixed {
  position: fixed;
  left: 0;
  bottom: 0;
  width: 100%;
  height: 50px;
  padding-top: 20px;
  box-sizing: border-box;
  background-color: #fff;
  z-index: 2;
}

.container {
  padding: 5px;
  box-sizing: border-box;

  .box {
    width: 100%;

    background-color: #fff;
    font-size: 14px;
    border-radius: 5px;
    color: #333;

    .title {
      border-bottom: 1px solid #eee;
      padding: 10px;
      box-sizing: border-box;

      &::before {
        content: "";
        display: inline-block;
        width: 4px;
        height: 20px;
        margin-right: 15px;
        background-color: #02a7f0;
        // 和标题对其
        vertical-align: middle;
      }
    }

    .content2 {
      padding: 0px 20px 20px 20px;
      box-sizing: border-box;
    }

    .content {
      padding: 0px 20px;
      box-sizing: border-box;

      .imgTitle {
        margin-top: 10px;
        box-sizing: border-box;
        font-weight: 700;
        padding-bottom: 10px;
        box-sizing: border-box;
        border-bottom: 1px solid #eee;
      }

      .imgbox {
        padding: 10px;
        box-sizing: border-box;
      }

      .fa {
        width: 100%;
        height: 30px;
        line-height: 30px;
        padding-left: 15px;
        box-sizing: border-box;
        background-color: #e3f5fd;
        margin: 10px;
      }
    }
  }
}

.mp {
  margin-top: 15px;
}

.mp10 {
  margin-top: 10px;
}

.pd50 {
  padding: 0 50px;
  box-sizing: border-box;
}

.left10 {
  padding-left: 20px;
  box-sizing: border-box;
}

.tp5 {
  margin-top: 5px;
  width: 148px;
  text-align: center;
  font-weight: 700;
  color: #8c939d;
}

.imgs {
  width: 130px;
  height: 130px;
  margin: 5px;
}

.center {
  width: 600px;
  text-align: center;
  margin: 10px auto;
}

.lable {
  width: 130px;
  display: inline-block;
  text-align: end;
}

.imgBox {
  //width: 148px;
  height: 148px;
  position: relative;
  //margin-bottom: 5px;
}

.imgBox:hover .img-icon {
  display: flex;
}

.imgBox:hover .img-icon {
  display: flex;
}

.bran {
  display: flex;
  align-items: center;
  margin-top: 10px;
  div {
    text-align: end;
    margin-right: 10px;
  }
}

.img-icon {
  width: 148px;
  height: 148px;
  border-radius: 6px;
  position: absolute;
  left: 0;
  top: 0;
  display: none;
  align-items: center;
  justify-content: center;
  gap: 10px;
  background-color: rgba(0, 0, 0, 0.4);

  i {
    font-size: 26px;
    color: #fff;
  }
}

.avatar-uploader {
  width: 100%;
}

.avatar-uploader ::v-deep .el-upload {
  //background-color: #fbfdff;
  border-radius: 6px;
  cursor: pointer;
  position: relative;
  overflow: hidden;
  display: flex;
  flex-wrap: wrap;
  gap: 10px;
}

.avatar-uploader .el-upload:hover {
  border-color: #409eff;
}

.avatar-uploader-icon {
  border: 1px dashed #c0ccda;
  font-size: 28px;
  color: #8c939d;
  width: 148px;
  height: 148px;
  line-height: 148px;
  text-align: center;
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 99;
}

/deep/.el-loading-spinner {
  margin-top: -74px;
}

.avatar {
  display: block;
  width: 148px;
  height: 148px;
  border-radius: 6px;
}

.imgBox {
  //width: 148px;
  height: 148px;
  position: relative;
  //margin-bottom: 5px;
}

.imgBox:hover .img-icon {
  display: flex;
}

.table {
  .el-button {
    height: 20px !important;
    line-height: 6px !important;
  }
}

::v-deep .el-table--enable-row-hover .el-table__body tr:hover > td {
  background-color: #f59a23 !important;
}

.text {
  text-align: center;
  margin-top: 10px;
}

::v-deep .el-table td {
  padding: 0 !important;
}

::v-deep .el-table th {
  padding: 0px !important;
}

.pdfbox {
  display: flex;

  .item {
    margin: 0 10px;
  }
}

.imgboxs {
  margin-left: 130px;
  margin-top: 5px;
  display: flex;

  & > div {
    margin: 0 10px;
  }
}

::v-deep .el-image {
  margin: 5px !important;
}

.success {
  color: #62a003;
  font-weight: 700;
}

.error {
  color: #d6000a;
  font-weight: 700;
}

.shenhebox {
  height: 500px;
  overflow: auto;
}

.tag {
  width: 80px;
  height: 30px;
  line-height: 30px;
  font-size: 16px;
  text-align: center;
}

.titlesss {
  color: red;
  display: inline-block;
  margin-right: 3px;
}
</style>
