<template>
  <div>
   <el-row class="mp">
            <el-col :span="8"
              ><span class="lable">勘探编号：</span>
              <span>{{ obj.explorateCode }}</span></el-col
            >

            <el-col :span="8"
              ><span class="lable">勘探时间：</span>
              <span>{{ obj.createDate }}</span>
            </el-col>
            <el-col :span="8"
              ><span class="lable">电站类型：</span>
              <span v-if="obj.powerstationType == 1">户用电站</span>
              <span v-if="obj.powerstationType == 2">工商业</span>
              <span v-if="obj.powerstationType == 3">大工商业</span>
            </el-col>
          </el-row>
          <el-row class="mp">
            <el-col :span="8"
              ><span class="lable">勘探方式：</span>
              <span v-if="obj.explorateType == 1">人工</span>
              <span v-if="obj.explorateType == 2">无人机</span>
            </el-col>
            <el-col :span="8"
              ><span class="lable">勘探人员：</span>
              <span>{{ obj.exploraterName }}</span>
            </el-col>
            <el-col :span="8"
              ><span class="lable">勘探人员手机号：</span>
              <span>{{ obj.exploraterPhone }}</span>
            </el-col>
          </el-row>
          <el-row class="mp">
            <el-col :span="8">
              <span class="lable">合作类型： </span>
              <span v-if="obj.cooperateType == 1">租屋顶</span>
              <span v-if="obj.cooperateType == 2">自然人</span>
              <span v-if="obj.cooperateType == 3">自建站</span>
              <span v-if="obj.cooperateType == 4">合约购</span>
              <span v-if="obj.cooperateType == 5">中亮包</span>
              <span v-if="obj.cooperateType == 6">租电站</span>
            </el-col>
            <!-- <el-col :span="8"
              ><span class="lable">组件规格：</span> <span>{{obj.componentSpecification}}</span>
            </el-col> -->
            <el-col :span="8"
              ><span class="lable">创建账号：</span>
              <span>{{ obj.createName }}</span>
            </el-col>
            <el-col :span="8"
              ><span class="lable">销售人员手机号：</span>
              <span>{{ obj.salerPhone }}</span></el-col
            >
          </el-row>

          <el-row class="mp">
            <el-col :span="8"
              ><span class="lable">销售人员：</span>
              <span>{{ obj.salerName }}</span></el-col
            >
          </el-row>
  </div>
</template>

<script>
export default {
  props:{
    obj: {
      typeof:Object,
      default: () => {}
    }
  }
}
</script>

<style scoped>
.mp {
  margin-top: 15px;
}

.lable {
  width: 130px;
  display: inline-block;
  text-align: end;
}
</style>
