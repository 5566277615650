<template>
  <div>
    <el-row>
      <el-col :span="4" style="position: relative">
        <uploadImg
          @emitUpload="emitUpload"
          :list="this.sceneEast"
          :text="'东'"
          :type="'scene_east'"
          :previewImgList="this.sceneEast"
        ></uploadImg>
      </el-col>
      <el-col :span="4" style="position: relative">
        <uploadImg
          @emitUpload="emitUpload"
          :list="this.sceneSouth"
          :text="'南'"
          :type="'scene_south'"
          :previewImgList="this.sceneSouth"
        >
        </uploadImg>
      </el-col>
      <el-col :span="4" style="position: relative">
        <uploadImg
          @emitUpload="emitUpload"
          :list="this.sceneWest"
          :text="'西'"
          :type="'scene_west'"
          :previewImgList="this.sceneWest"
        ></uploadImg>
      </el-col>
      <el-col :span="4" style="position: relative">
        <uploadImg
          @emitUpload="emitUpload"
          :list="this.sceneNorth"
          :text="'北'"
          :type="'scene_north'"
          :previewImgList="this.sceneNorth"
        >
        </uploadImg>
      </el-col>
      <el-col :span="4" style="position: relative">
        <uploadImg
          @emitUpload="emitUpload"
          :list="this.sceneSouthEast"
          :text="'东南'"
          :type="'scene_southeast'"
          :previewImgList="this.sceneSouthEast"
        >
        </uploadImg>
      </el-col>
      <el-col :span="4" style="position: relative">
        <uploadImg
          @emitUpload="emitUpload"
          :list="this.sceneSouthwest"
          :text="'西南'"
          :type="'scene_southwest'"
          :previewImgList="this.sceneSouthwest"
        >
        </uploadImg>
      </el-col>
    </el-row>
    <el-row>
      <el-col :span="4" style="position: relative">
        <uploadImg
          @emitUpload="emitUpload"
          :list="this.sceneInternal"
          :text="'房屋内部照片'"
          :type="'scene_internal'"
          :previewImgList="this.sceneInternal"
        >
        </uploadImg>
      </el-col>
      <el-col :span="4" style="position: relative">
        <uploadImg
          @emitUpload="emitUpload"
          :list="this.roofStructure"
          :text="'房顶内部结构照片'"
          :type="'roof_structure'"
          :previewImgList="this.roofStructure"
        >
        </uploadImg>
      </el-col>
    </el-row>
  </div>
</template>

<script>
import uploadImg from "./uploadImg";
export default {
  components: { uploadImg },
  props: {
    sceneEast: {
      typeof: Array,
      default: () => [],
    },
    sceneSouth: {
      typeof: Array,
      default: () => [],
    },
    sceneWest: { typeof: Array, default: () => [] },
    sceneNorth: { typeof: Array, default: () => [] },
    sceneSouthEast: { typeof: Array, default: () => [] },
    sceneSouthwest: { typeof: Array, default: () => [] },
    sceneInternal: { typeof: Array, default: () => [] },
    roofStructure: { typeof: Array, default: () => [] },
  },
  computed:{

  }
};
</script>

<style></style>
