<template>
  <div>
    <el-row class="mp">
      <el-col :span="8"
        ><span class="lable">用户编号：</span>
        <span>{{ obj.customer.customerNumber }}</span>
      </el-col>

      <el-col :span="8"
        ><span class="lable">用户姓名：</span>
        <span>{{ obj.customer.customerName }}</span>
      </el-col>
      <el-col :span="8"
        ><span class="lable">手机号：</span>
        <span>{{ obj.customer ? obj.customer.mobile : "" }}</span>
      </el-col>
    </el-row>
    <el-row class="mp">
      <el-col :span="8"
        ><span class="lable">性别：</span>
        <span v-if="obj.customer.sex == 1">男</span>
        <span v-if="obj.customer.sex == 2">女</span>
        <span v-if="obj.customer.sex == 3">未知</span>
      </el-col>
      <el-col :span="8"
        ><span class="lable">证件类型：</span>
        <span>{{ obj.certificateType == 1 ? "身份证" : "其他" }}</span>
      </el-col>
      <el-col :span="8"
        ><span class="lable">身份证：</span>
        <span>{{ obj.customer.certificateNo }}</span>
      </el-col>
    </el-row>
    <el-row class="mp">
      <el-col :span="8"
        ><span class="lable">代理商编号：</span>
        <span>{{ obj.agentCode }}</span>
      </el-col>
      <el-col :span="8"
        ><span class="lable">代理商名称：</span><span>{{ obj.agentName }}</span>
      </el-col>
      <el-col :span="8"
        ><span class="lable">所属分公司：</span
        ><span>{{ obj.customer.companyName }}</span>
      </el-col>
    </el-row>
    <el-row class="mp">
      <el-col :span="8" class="flex"
        ><span class="lable">用户地址：</span>
        <span class="omit" >{{ obj.customer.address }}</span>
      </el-col>
      <el-col :span="8"  class="flex" v-if="obj.customer.customerType == 3"
        ><span class="lable">公司名称：</span>
        <span class="omit">{{ obj.customer.address }}</span>
      </el-col>
      <el-col :span="8" v-if="obj.customer.customerType == 3" class="flex"
        ><span class="lable">法人姓名：</span>
        <span class="omit">{{ obj.customer.address }}</span>
      </el-col>
    </el-row>
    <el-row class="mp" v-if="obj.customer.customerType == 3">
      <el-col :span="8" class="flex"
        ><span class="lable">法人手机号：</span>
        <span class="omit">{{ obj.customer.address }}</span>
      </el-col>
      <el-col :span="8" class="flex"
        ><span class="lable">公司客户类型：</span>
        <span class="omit">{{ obj.customer.address }}</span>
      </el-col>
    </el-row>
    <div class="mp">
      <div class="lable">身份证</div>
      <div class="imgboxs">
        <uploadImg
          @emitRemove="emitRemove"
          :imgURL="imgs.certificateImg1"
          :text="'身份证正面'"
          :type="'certificateImg1'"
        ></uploadImg>
        <uploadImg
          @emitRemove="emitRemove"
          :imgURL="imgs.certificateImg2"
          :text="'身份证背面'"
          :type="'certificateImg2'"
        ></uploadImg>
      </div>
    </div>

    <div class="mp">
      <div class="lable">银行卡</div>
      <div class="imgboxs">
        <uploadImg
          @emitRemove="emitRemove"
          :imgURL="imgs.bankcardImg1"
          :text="'银行卡正面'"
          :type="'bankcardImg1'"
        ></uploadImg>

        <div style="position: relative">
          <div class="bran">
            <div class="label">卡 &ensp; 号</div>
            <div class="inputbox">
              <el-input
                v-model="obj.customer.bankcardNumber"
                disabled
              ></el-input>
            </div>
          </div>
          <div class="bran">
            <div class="label">开户行</div>
            <div class="inputbox">
              <el-input   v-model="obj.customer.bankcardName" disabled></el-input>
            </div>
          </div>
          <div class="bran">
            <div class="label">银联号</div>
            <div class="inputbox">
              <el-input v-model="obj.customer.bankcardNo" disabled></el-input>
            </div>
          </div>
        </div>
      </div>
    </div>

  </div>
</template>

<script>
import uploadImg from "./uploadImgObj.vue";
export default {
  props: {
    obj: {
      typeof: Object,
      default: {},
    },
    // customerImg: {
    //   typeof: Object,
    //   default: {},
    // },
  },
  components: { uploadImg },

  computed: {
    imgs() {
      return this.$store.state.expoleManageDetail.imgs;
    },
  },
  methods: {
    emitRemove(item) {
      this.$store.commit("expoleManageDetail/removeImg", item);
    },
  },
};
</script>

<style scoped lang="scss">
.mp {
  margin-top: 15px;
}

.lable {
  width: 130px;
  display: inline-block;
  text-align: end;
}

.imgboxs {
  margin-left: 130px;
  margin-top: 5px;
  display: flex;

  & > div {
    margin: 0 10px;
  }
}

::v-deep .el-image {
  margin: 5px !important;
}

.bran {
  display: flex;
  align-items: center;
  margin-top: 10px;
  .inputbox {
    margin-left: 10px;
    width: 300px;
  }
}

.omit {
  display: inline-block;
  width: 300px;
  // 文本省略
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;

}
</style>
