<template>
  <div>
    <div class="box mp10">
      <div class="title">房屋照片</div>
      <div class="content">
        <div class="imgTitle">现勘表照片（{{ surveyTable.length }}/10）</div>
        <div class="imgbox">
              <div>现勘表照片：</div>
            <div style="position: relative">
                     <uploadImg
              @emitUpload="emitUpload"
              :list="this.surveyTable"
              :text="'现勘表照片：'"
              :type="'survey_table'"
              :previewImgList="this.surveyTable"
            >
            </uploadImg>
        </div>
      </div>
    </div>
  </div>
  </div>
</template>

<script>
import uploadImg from "./uploadImgList.vue";
export default {
  components: { uploadImg },
  computed: {
    surveyTable() {
      return this.$store.state.expoleManageDetail.surveyTable.map(
        (item) => item.explorateImgUrl
      );
    },
  },
  methods: {
    emitUpload() {},
  },
};
</script>

<style lang="scss" scoped>
.box {
  width: 100%;

  background-color: #fff;
  font-size: 14px;
  border-radius: 5px;
  color: #333;

  .title {
    border-bottom: 1px solid #eee;
    padding: 10px;
    box-sizing: border-box;

    &::before {
      content: "";
      display: inline-block;
      width: 4px;
      height: 20px;
      margin-right: 15px;
      background-color: #02a7f0;
      // 和标题对其
      vertical-align: middle;
    }
  }

  .content2 {
    padding: 0px 20px 20px 20px;
    box-sizing: border-box;
  }

  .content {
    padding: 0px 20px;
    box-sizing: border-box;

    .imgTitle {
      margin-top: 10px;
      box-sizing: border-box;
      font-weight: 700;
      padding-bottom: 10px;
      box-sizing: border-box;
      border-bottom: 1px solid #eee;
    }

    .imgbox {
      padding: 10px;
      box-sizing: border-box;
    }

    .fa {
      width: 100%;
      height: 30px;
      line-height: 30px;
      padding-left: 15px;
      box-sizing: border-box;
      background-color: #e3f5fd;
      margin: 10px;
    }
  }
}
</style>
