<template>
  <div class="box">
    <el-dialog title="请选择物料" :visible.sync="drawer" class="selectorDialog">
      <div class="org-selected">
        <el-row>
          <el-col :span="6" class="selectedUl pr10 bbox">
            <div class="selectedUl-box topmar">
              <ul>
                <li
                  class="fixKist"
                  :class="select == i ? 'spanActive' : ''"
                  v-for="(item, i) in materialFist"
                  :key="item.id"
                >
                  <span
                    class="floatLeft rightOrderBox"
                    @click="toSelect(i, item)"
                    >{{ item.product_type_name }}</span
                  >
                  <!-- <i class="el-icon-close floatRight iconOrganization iconblue fw700 f15"
                                        @click="delAuthority(item.id, i)"></i> -->
                </li>
              </ul>
            </div>

            <!-- <p class="wp100 bbox flex justify-between textblue">
                            <span>已选择</span>
                            <span class="pointer" @click="emptyAuthority"><i class="el-icon-delete"></i> 清空</span>
                        </p>
                        <div class="selectedUl-box">
                            <ul>
                                <li class="clearfix" v-for="(item, i) in multipleSelection" :key="item.id">
                                    <span class="floatLeft rightOrderBox">{{
                                        item.Followway
                                        }}</span>
                                    <i class="el-icon-close floatRight iconOrganization iconblue fw700 f15"
                                        @click="delAuthority(item.id, i)"></i>
                                </li>
                            </ul>
                        </div> -->
          </el-col>
          <el-col :span="18">
            <el-form :inline="true" :model="form" class="demo-form-inline">
              <el-form-item>
                <el-input
                  v-model="form.productName"
                  placeholder="请输入物料名称"
                  clearable
                ></el-input>
              </el-form-item>
              <el-form-item>
                <el-button type="primary" @click="onSubmit">查询</el-button>
              </el-form-item>
            </el-form>
            <el-table
              class="fontSize-table"
              :row-style="{ height: '20px' }"
              :cell-style="{ padding: '0px' }"
              header-row-class-name="table-head-row"
              :data="tableData"
              @selection-change="handleSelectionChange"
              border
              height="calc(100vh - 296px)"
              style="width: 100%; margin-top: 20px"
            >
              <el-table-column type="selection" width="35"></el-table-column>
              <el-table-column
                min-width="118px"
                label="物料名称"
                prop="product_name"
              >
              </el-table-column>
              <el-table-column
                show-overflow-tooltip
                prop="specs"
                label="规格/型号"
                min-width="100px"
              >
              </el-table-column>
              <el-table-column
                show-overflow-tooltip
                prop="brand"
                label="品牌"
                min-width="100px"
              >
              </el-table-column>
              <el-table-column
                show-overflow-tooltip
                prop="power"
                label="功率"
                min-width="100px"
              >
              </el-table-column>
              <el-table-column
                show-overflow-tooltip
                prop="unit"
                label="单位"
                min-width="100px"
              >
              </el-table-column>
            </el-table>
            <!-- 分页 -->
            <!-- <el-pagination @size-change="handleSizeChangeAuthority"
                            @current-change="handleCurrentChangeAuthority" :current-page="queryInfoAuthority.currPage"
                            :page-size="queryInfoAuthority.pageSize" layout="total, prev, pager, next"
                            :total="totleAuthority" class="ml10" style="margin-bottom: 15px"></el-pagination> -->
          </el-col>
        </el-row>
      </div>
      <div slot="footer" class="dialog-footer">
        <el-button type="primary" @click="confirmAuthority">确 认</el-button>
        <el-button @click="drawer = false">取 消</el-button>
      </div>
    </el-dialog>
  </div>
</template>
<script>
export default {
  data() {
    return {
      drawer: false,
      tableData: [],
      form: {
        productName: "",
      },
      list: [{ name: "张三" }],
      queryInfoAuthority: {
        // 当前页数
        currPage: 1,
        // 每页显示多少数据
        pageSize: 100,
      },
      totleAuthority: 20,

      multipleSelection: [],
      materialFist: [],
      select: "0",
      selectItem: "",
    };
  },
  created() {},
  methods: {
    toSelect(i, item) {
      console.log(i);
      this.select = i;
      this.selectItem = item;
      this.getAddress(item);
    },
    init() {
      this.drawer = true;
      this.getConstruction();
    },
    // 获取选择施工队
    async getConstruction() {
      let res = await this.$http.post("/PricePlans/selecttypelist", {});
      this.materialFist = res.data.data;
      this.getAddress(this.materialFist[0]);
    },
    // 选中的数据
    handleSelectionChange(val) {
      this.multipleSelection = val;
    },
    // 查询
    onSubmit() {
      this.$http
        .post("/PricePlans/logisticslist", {
          productName: this.form.productName,
        })
        .then((res) => {
          if (res.data.code === 200) {
            this.tableData = res.data.data;
          } else {
            this.$message({
              type: "error",
              message: res.data.message,
              duration: 2000,
            });
          }
          // console.log(res.data.data);
        });
    },
    //删除
    delAuthority(id, i) {
      this.tableData.forEach((row) => {
        if (row.id == id) {
          this.$refs.multipleTable.toggleRowSelection(row, false);
        }
      });
      // this.multipleSelection.splice(i, 1);
    },
    // 监听 pagesize改变的事件
    handleSizeChangeAuthority(newSize) {
      // console.log(newSize)
      this.queryInfoAuthority.pageSize = newSize;
      // this.getRoleInfo();
    },
    // 监听 页码值 改变事件
    handleCurrentChangeAuthority(newSize) {
      // console.log(newSize)
      this.queryInfoAuthority.currPage = newSize;
      // this.getRoleInfo();
    },

    // 获取列表数据
    async getAddress(item) {
      let data = {
        productTypeCode: item.product_type_code,
      };
      let res = await this.$http.post("PricePlans/logisticslist", data);
      this.tableData = res.data.data;
    },
    handleClose(done) {
      this.$confirm("确认关闭？")
        .then((_) => {
          done();
        })
        .catch((_) => {});
    },
    confirmAuthority() {
      this.drawer = false;
      console.log(this.multipleSelection);
      this.$emit("toMaterial", this.multipleSelection);
    },
  },
};
</script>
<style lang="less" scoped>
.box {
  width: 70%;

  .rightOrderBox {
    width: 80%;
  }

  .span {
    width: 5px;
    height: 22px;
    background-color: #02a7f0;
    position: absolute;
    top: -10px;
    left: -5px;
  }

  // .span2 {
  //     width: 5px;
  //     height: 22px;
  //     background-color: #02a7f0;
  //     position: absolute;
  //     top: -10px;
  //     left: 190px;
  //     margin-right: 7px;
  // }

  .title {
    position: absolute;
    top: -25px;
    font-size: 16px;
    font-weight: 800;
  }

  .topmar {
    margin-top: 20px;

    .fixKist {
      box-sizing: border-box;
      width: 100%;
      height: 40px;
      line-height: 16px;
      text-align: center;
      margin-bottom: 0px;
      background: #fff;
      padding: 14px 0 12px 27px;
    }

    .spanActive {
      background: rgba(47, 145, 223, 0.1);
    }
  }

  // /deep/ .el-form-item {
  //     margin-bottom: 16px !important;
  // }
  .selectorDialog .selectedUl {
    border-left: 0;
  }

  /deep/ .el-dialog__body {
    padding: 0 20px;
  }
}
</style>
